import React from 'react'
import Img from 'gatsby-image'
import OrangeDS from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import dataJson from './assets/data/data.json'
import usePageQuery from '../../pageQuery'
import * as S from './style'
import { IOpenModalProps } from '../../types'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

interface IPlans {
    id: string;
    plan: string;
    image: {
      src: string;
      alt: string;
    };
    price: string;
    beneficts: string[];
    range: string;
}

interface ISeguroPixInter extends IOpenModalProps {
  setDataLayer: (state: IDataLayerParams) => void;
}

const PlanosBaratinhosParaVoce = ({ handleClick, setDataLayer }: ISeguroPixInter) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const plans: IPlans[] = dataJson
  const data = usePageQuery()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_03',
    section_name: 'Seguro Pix: planos baratinhos pra você viver numa boa!',
    element_action: 'click button',
    element_name: 'Escolher meu plano',
  }

  return (
    <S.SectionWrapper id='planos-baratinhos-para-voce'>
      <div className='container py-5'>
        <div className='row align-items-lg-center'>
          <div className='col-12 col-lg-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 text-center text-lg-left'><span className='text-orange--extra d-block d-md-inline d-lg-block'>Seguro Pix:</span> planos baratinhos <span className='d-block d-lg-inline'><span className='d-lg-block d-xl-inline'>pra você viver</span> numa boa!</span></h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--400 text-center text-lg-left'>Encontre o plano ideal para proteger <span className='d-lg-block d-xl-inline'>ainda mais <span className='d-md-block d-lg-inline'>suas transações bancárias.</span></span></p>
            <div className='d-none d-lg-block mt-5'>
              <button
                className='btn btn-orange--extra fs-14 fw-600 rounded-2 d-flex justify-content-between text-white text-none'
                onClick={() => {
                  handleClick()
                  sendDatalayerEvent(dataLayer)
                  setDataLayer(dataLayer)
                }}
              >
                Escolher meu plano
                <OrangeDS color='white' size='MD' icon='chevron-right' className='ml-5' />
              </button>
            </div>
          </div>
          <div className='col-12 d-md-none'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 30 }}
            >
              {
                plans.map((item: IPlans) => (
                  <div key={item.id}>
                    <S.Card
                      className='mr-4'
                      onClick={() => {
                        handleClick()
                        sendDatalayerEvent({
                          ...dataLayer,
                          element_name: item.plan,
                        })
                        setDataLayer({
                          ...dataLayer,
                          element_name: item.plan,
                        })
                      }}
                    >
                      <div className='dashed-underline mt-4 mx-3'>
                        <div className='d-flex align-items-center pb-3'>
                          <Img fluid={data[item.image.src].fluid} alt={item.image.alt} />
                          <div className='ml-3'>
                            <h3 className='fs-16 lh-20 fw-700 text-grayscale--500 mb-1'>{item.plan}</h3>
                            <p className='fs-14 lh-17 fw-700 text-grayscale--400 mb-0'>{item.price}</p>
                          </div>
                        </div>
                      </div>
                      <div className='px-3 my-3'>
                        {item.beneficts.map((text: string) => (
                          <div className='d-flex align-items-center my-2' key={text}>
                            <div className='mr-2'>
                              <OrangeDS icon='check' size='SM' color='#00AA4F' />
                            </div>
                            <div>
                              <p className='fs-14 lh-17 fw-400 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: text }} />
                            </div>
                          </div>
                      ))}
                      </div>
                      <div className='price-range-container p-3'>
                        <div>
                          <p className='fs-14 lh-17 fw-400 text-white mb-1'>Coberturas de até</p>
                          <p className='fs-18 lh-22 fw-700 text-white mb-0'>{item.range}</p>
                        </div>
                      </div>
                    </S.Card>
                  </div>
                ))
              }
            </DefaultCarousel>
          </div>
          <div className='col-12 col-lg-7 col-xl-6 offset-xl-1 d-none d-md-block'>
            <div className='row'>
              {
                plans.map((item: IPlans) => (
                  <div key={item.id} className='col-md-6'>
                    <S.Card
                      onClick={() => {
                        handleClick()
                        sendDatalayerEvent({
                          ...dataLayer,
                          element_name: item.plan,
                        })
                        setDataLayer({
                          ...dataLayer,
                          element_name: item.plan,
                        })
                      }}
                    >
                      <div className='dashed-underline mt-4 mx-3'>
                        <div className='d-flex align-items-center pb-3'>
                          <Img fluid={data[item.image.src].fluid} alt={item.image.alt} />
                          <div className='ml-3'>
                            <h3 className='fs-16 lh-20 fw-700 text-grayscale--500 mb-1'>{item.plan}</h3>
                            <p className='fs-14 lh-17 fw-700 text-grayscale--400 mb-0'>{item.price}</p>
                          </div>
                        </div>
                      </div>
                      <div className='px-3 my-3'>
                        {item.beneficts.map((text: string) => (
                          <div className='d-flex align-items-center my-2' key={text}>
                            <div className='mr-2'>
                              <OrangeDS icon='check' size='SM' color='#00AA4F' />
                            </div>
                            <div>
                              <p className='fs-14 lh-17 fw-400 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: text }} />
                            </div>
                          </div>
                      ))}
                      </div>
                      <div className='price-range-container p-3'>
                        <div>
                          <p className='fs-14 lh-17 fw-400 text-white mb-1'>Coberturas de até</p>
                          <p className='fs-18 lh-22 fw-700 text-white mb-0'>{item.range}</p>
                        </div>
                      </div>
                    </S.Card>
                  </div>
                ))
                }
            </div>

          </div>
          <div className='col-12 mt-4 mt-md-5 d-lg-none'>
            <button
              className='btn btn-orange--extra fs-14 fw-600 rounded-2 ml-md-2 ml-lg-3 d-flex justify-content-between text-white text-none mx-md-auto'
              onClick={() => {
                handleClick()
                sendDatalayerEvent(dataLayer)
                setDataLayer(dataLayer)
              }}
            >
              Escolher meu plano
              <OrangeDS color='white' size='MD' icon='chevron-right' className='ml-5' />
            </button>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default PlanosBaratinhosParaVoce
