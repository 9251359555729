import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import * as S from './style'

import jsonData from './data/data.json'

import Smartphones from 'inter-frontend-svgs/lib/orangeds/MD/smartphones'
import TransferMoney from 'inter-frontend-svgs/lib/orangeds/MD/transfer-money'
import { orange } from 'src/styles/colors'
import ScrollTo from 'src/components/ScrollTo'

interface IIconsList {
  [index: string]: React.ReactNode;
}

interface IDataJson {
  id: string;
  icon: string;
  title: string;
  description: string;
}

const ComoFuncionaoSeguroPixInter = () => {
  const dataJson: IDataJson[] = jsonData

  const icons: IIconsList = {
    smartphone: <Smartphones color={orange.extra} width={32} height={32} />,
    transferMoney: <TransferMoney color={orange.extra} width={32} height={32} />,
  }

  const data = usePageQuery()
  return (
    <S.SectionWrapper>
      <div className='container py-5'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5'>
            <div className='mb-3'>
              <Img fluid={data.escudoPix.fluid} alt='Seguro Pix Inter' />
            </div>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500'>Como funciona o <br /> <span className='text-orange--extra'>Seguro Pix</span> Inter?</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--400'>Ative e receba uma indenização rápida e sem complicação para as transações indevidas cobertas pelo seu plano.</p>
          </div>
          <div className='col-12 col-md-6 col-lg-7 col-xl-6 offset-xl-1'>
            <S.CardContainer>
              {
              dataJson.map((item: IDataJson) => (
                <S.Card key={item.id} className='mb-4'>
                  <div className='d-flex'>
                    <div className='mr-3'>
                      {icons[item.icon]}
                    </div>
                    <div>
                      <h3 className='fs-16 lh-20 fs-lg-18 lh-lg-22 fs-xl-20 lh-xl-26 text-grayscale--500 fw-700 mb-3' dangerouslySetInnerHTML={{ __html: item.title }} />
                      <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-grayscale--400 fw-400 mt-2 mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                  </div>
                </S.Card>
              ))
            }
            </S.CardContainer>
            <div className='pt-lg-4'>
              <ScrollTo
                to='#planos-baratinhos-para-voce'
                section='dobra_02'
                sectionName='Como funciona o Seguro Pix Inter?'
                elementName='Ver planos'
                styles='btn btn--lg btn-orange--extra fs-14 fw-700 rounded-3 ml-lg-0 text-none mw-100'
              >
                Ver planos
              </ScrollTo>
            </div>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default ComoFuncionaoSeguroPixInter
