import styled from 'styled-components'

import BackgroundSM from '../../assets/images/background-hero-seguros-pix-mobile.png'
import BackgroundMD from '../../assets/images/background-hero-seguros-pix-md.png'
import BackgroundLG from '../../assets/images/background-hero-seguros-pix-lg.png'
import BackgroundXL from '../../assets/images/background-hero-seguros-pix-xl.png'

import { orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
  background-color: ${orange.extra};
  background-image: url(${BackgroundSM});
  background-repeat: no-repeat;
  background-position: top top;
  background-size: contain;
  height: 640px;

  @media ${device.tablet} {
    background-image: url(${BackgroundMD});
    background-position-x: 345px;
    background-position-y: center;
    background-size: auto;
    height: 418px;
  }

  @media ${device.desktopLG} {
    background-image: url(${BackgroundLG});
    background-position-x: 440px;
    height: 590px;
  }

  @media ${device.desktopXL} {
    background-image: url(${BackgroundXL});
    background-position-x: right;
    height: 644px;
  }
`
