import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      escudoPix: imageSharp(fluid: {originalName: { regex: "/escudo-pix/" }}) {
        fluid(maxWidth: 226, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      imagePlanoStandard: imageSharp(fluid: {originalName: { regex: "/image-plano-standard/" }}) {
        fluid(maxWidth: 64, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      imagePlanoPlus: imageSharp(fluid: {originalName: { regex: "/image-plano-plus/" }}) {
        fluid(maxWidth: 64, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
