import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const SectionWrapper = styled.section`
  background-color: ${orange[100]};
  .btn, a {
    height: 48px;
    @media ${device.tablet} {
      width: 336px !important;
    }
    @media ${device.desktopLG} {
      width: 355px !important;
    }
  }
`

export const Card = styled.div`
  cursor: pointer;
  .gatsby-image-wrapper {
    width: 64px;
    height: 64px;
  }

  border: 1px solid ${orange.extra};
  border-radius: 8px;
  background-color: ${white};
  .price-range-container {
    border-radius: 0 0 8px 8px;
    background-color: ${orange.extra};
  }

  .dashed-underline {
    border-bottom: 1px dashed ${grayscale[200]};
  }
`
