import React, { useState } from 'react'

import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'
import SeguroPixForm from './components/SeguroPixForm/_index'
import useDomReady from 'src/hooks/window/useDomReady'

import {
  SeguroPixInter,
  ComoFuncionaoSeguroPixInter,
  PlanosBaratinhosParaVoce,
  ComoContrataroSeguroPix,
  Faq,
} from './sections/_index'

import { Modal } from 'src/components/Modal'
import { Wrapper } from './style'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

function Seguros () {
  const domReady = useDomReady()
  const [ openModal, setOpenModal ] = useState(false)

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Seguro Pix Inter: segurança pra você viver o que quiser.',
    element_action: 'click button',
    element_name: 'Conhecer o Seguro Pix',
  })

  const AppSimulationModal = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <SeguroPixForm closeModal={() => setOpenModal(false)} closeFirstModal={setOpenModal} dataLayer={dataLayer} />
    </Modal>
  )

  const handleClick = () => {
    setOpenModal(!openModal)
  }

  return (
    <Wrapper>
      <Layout pageContext={pageContext} dataLayer={dataLayer}>
        {AppSimulationModal}
        <SeguroPixInter handleClick={handleClick} setDataLayer={setDataLayer} />
        <ComoFuncionaoSeguroPixInter />
        <PlanosBaratinhosParaVoce handleClick={handleClick} setDataLayer={setDataLayer} />
        <ComoContrataroSeguroPix handleClick={handleClick} setDataLayer={setDataLayer} />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default Seguros
