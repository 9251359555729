import React from 'react'
import { IOpenModalProps } from '../../types'
import * as S from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

interface ISeguroPixInter extends IOpenModalProps {
  setDataLayer: (state: IDataLayerParams) => void;
}

const SeguroPixInter = ({ handleClick, setDataLayer }: ISeguroPixInter) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Seguro Pix Inter: segurança pra você viver o que quiser.',
    element_action: 'click button',
    element_name: 'Conhecer o Seguro Pix',
  }

  return (
    <S.SectionWrapper className='d-flex align-items-end align-items-md-center pb-5 pt-md-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-white'>
              Seguro Pix Inter: <span className='d-block'>segurança pra você</span> viver o que quiser.
            </h1>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-white'>
              Viva os melhores momentos numa boa! Com o Seguro Pix, você tem coberturas para transações indevidas sob
              ameaça ou roubo do celular.
            </p>
            <div>
              <button
                className='btn--lg mt-4 btn btn-white text-orange--extra rounded-2 fs-14 fw-600 text-none mw-100'
                onClick={() => {
                  handleClick()
                  sendDatalayerEvent(dataLayer)
                  setDataLayer(dataLayer)
                }}
              >
                Conhecer o Seguro Pix
              </button>
            </div>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default SeguroPixInter
