import React, { useState } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import QRCodeCorrentista from './assets/images/qrcode-correntista-seguro-pix.jpg'
import QRCodeNaoCorrentista from './assets/images/qrcode-nao-correntista-seguro-pix.jpg'
import { Container, CloseButton } from './style'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDomReady from 'src/hooks/window/useDomReady'

type SeguroPixFormProps = {
  closeModal: () => void;
  closeFirstModal: (state: boolean) => void;
  dataLayer: IDataLayerParams;
}

function SeguroPixForm ({ closeModal, closeFirstModal, dataLayer }: SeguroPixFormProps) {
  const [ openAccount, setOpenAccount ] = useState(false)
  const [ alreadyHaveAccount, setAlreadyHaveAccount ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isOpen, setIsOpen ] = useState(false)
  const domReady = useDomReady()
  const urlCorrentista = 'https://intergo.app/a7d0d383'
  const urlNaoCorrentista = 'https://intergo.app/6496c86b'

  const openModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        instructions='Abra a câmera do seu celular e aponte para o QR Code acima. É só baixar o app do Inter. É rápido e fácil!'
        qrCode={QRCodeCorrentista}
        closeFirstModal={closeFirstModal}
      />
    </Modal>
  )

  const openAccountRightForm = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        closeModal={closeModal}
        formName='Formulario seguros abertura de contas'
        qrBaixeApp={QRCodeNaoCorrentista}
        deepLink={urlNaoCorrentista}
        dataLayer={dataLayer}
      />
    </Modal>
  )

  const closeButton = closeModal && (
    <CloseButton className='border-0 bg-transparent pt-4' onClick={closeModal}>
      <Close width='24' height='24' color='orange--extra' />
    </CloseButton>
  )

  return (
    <>
      {openAccount && (
        <>
          {openAccountRightForm}
        </>
      )}
      {alreadyHaveAccount && (
        <>
          {openModal}
        </>
      )}
      <Container isModal={closeModal} className={`${ openAccount || alreadyHaveAccount ? 'd-none' : 'd-flex'} align-items-md-center`}>
        {closeButton}
        <div className='row pt-5 pt-md-0'>
          <div className='col-12 pr-0 py-4 pt-lg-0'>
            <h2 className='fs-20 lh-24 fs-md-32 lh-md-40 fw-600 mb-3 mb-md-4 text-grayscale--500'>
              Já tem conta no Inter?
            </h2>
            <div>
              <p className='fs-16 lh-20 text-grayscale--400'>Você pode conhecer, simular e contratar toda proteção da Inter Seguros pelo Super App do Inter.  </p>
            </div>
          </div>
          <div className='col-12 d-flex d-md-block align-items-end'>
            <div className='open-account d-md-flex justify-content-md-between pb-4'>
              <div className='d-none d-lg-block'>
                <button
                  onClick={() => {
                    setAlreadyHaveAccount(true)
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_1',
                      section_name: dataLayer.section_name,
                      element_action: dataLayer.element_action,
                      element_name: 'Já tenho conta',
                      element_previous: dataLayer.element_name,
                      step: 'success',
                    })
                  }}
                  className='btn btn--lg btn--white rounded-2 fs-14 fw-600 text-orange--extra text-none mb-3 mb-md-0'
                >
                  Já tenho conta
                </button>
              </div>
              <div className='d-block d-lg-none'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={urlCorrentista}
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_0',
                      section_name: dataLayer.section_name,
                      element_action: dataLayer.element_action,
                      element_name: 'Já tenho conta',
                      element_previous: dataLayer.element_name,
                      step: 'success',
                    })
                  }}
                  className='btn btn--lg btn--white rounded-2 fs-14 fw-600 text-orange--extra text-none mb-3 mb-md-0'
                >
                  Já tenho conta
                </a>
              </div>
              <button
                onClick={() => {
                  setOpenAccount(true)
                  setIsOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_1',
                    section_name: dataLayer.section_name,
                    element_action: dataLayer.element_action,
                    element_name: 'Não tenho conta',
                    element_previous: dataLayer.element_name,
                    step: '1',
                   })
                }}
                className='btn btn--lg btn-orange--extra rounded-2 fs-14 fw-600 text-none'
              >
                Não tenho conta
              </button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default SeguroPixForm
