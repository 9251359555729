import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const SectionWrapper = styled.section`
  .gatsby-image-wrapper {
    width: 86px !important;
    height: 107px !important;

    @media ${device.tablet} {
      width: 226px !important;
      height: 281px !important;
    }
  }

  .btn, a {
    height: 48px;
  }
`

export const Card = styled.div`
  min-height: 163px;
  border-radius:8px;
  padding: 16px;
  border: 1px solid ${grayscale[200]};

  @media ${device.tablet} {
    min-height: 146px;
  }

  @media ${device.desktopXL} {
    min-height: 154px;
  }
`

export const CardContainer = styled.div`

`
