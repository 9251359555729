import React from 'react'

import dataJson from './assets/data/data.json'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import AppStorePT from 'inter-frontend-svgs/lib/v2/social/store/app-store'
import PlayStorePT from 'inter-frontend-svgs/lib/v2/social/store/google-play'

import * as S from './style'
import { IOpenModalProps } from '../../types'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
interface ISteps {
  id: string;
  order: string;
  title: string;
  description: string;
  socialMediaIcons: boolean;
}

interface ISeguroPixInter extends IOpenModalProps {
  setDataLayer: (state: IDataLayerParams) => void;
}

const ComoContrataroSeguroPix = ({ handleClick, setDataLayer }: ISeguroPixInter) => {
  const plans: ISteps[] = dataJson
  const [ sendDatalayerEvent ] = useDataLayer()
  const dataLayer: IDataLayerParams = {
    section: 'dobra_04',
    section_name: 'Como contratar o Seguro Pix pelo Super App?',
    element_action: 'click button',
    element_name: 'Contratar agora',
  }

  return (
    <S.SectionWrapper className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-3'><span className='d-md-block'>Como contratar o </span><span className='text-orange--extra'>Seguro Pix</span> pelo <span className='d-block d-md-inline'>Super App?</span></h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--400 mb-md-0'>O Seguro Pix Inter é exclusivo para nossos clientes. Siga o passo a passo, <span className='d-md-block'>abra sua conta e contrate sem complicações.</span></p>
          </div>
          <div className='col-12 last-child-list'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 50 }}
              md={{ items: 3 }}
              lg={{ items: 4 }}
            >
              {
                plans.map((item: ISteps) => (
                  <div key={item.id} className='last-child'>
                    <S.Card className='mr-4'>
                      <div className='title-container d-flex align-items-center'>
                        <div className='mr-2'>
                          <S.Step className='d-flex align-items-center justify-content-center'>
                            <span className='fs-12 lh-15 text-white fw-700 font-sora'>
                              {item.order}
                            </span>
                          </S.Step>
                        </div>
                        <div>
                          <h3 className='fs-16 lh-20 fw-700 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: item.title }} />
                        </div>
                      </div>
                      <div>
                        <p className='fs-14 lh-17 fw-400 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                      </div>
                      { item.socialMediaIcons && (
                        <div className='mt-4 mt-lg-3'>
                          <div className='d-flex justify-content-between'>
                            <a
                              href='https://play.google.com/store/apps/details?id=br.com.intermedium'
                              target='_blank' rel='noreferrer'
                              title='Baixe no Google Play' aria-label='Google Play' data-footer='download-google-play'
                            >
                              <PlayStorePT width={100} height={30} />
                            </a>
                            <a
                              href='https://itunes.apple.com/br/app/banco-inter/id839711154'
                              target='_blank' rel='noreferrer'
                              title='Baixe na App Store' aria-label='App Store' data-footer='download-app-store'
                            >
                              <AppStorePT width={100} height={30} />
                            </a>
                          </div>
                        </div>
                      )}
                    </S.Card>
                  </div>
                ))
              }
            </DefaultCarousel>
          </div>
          <div className='col-12'>
            <div className='pt-4 d-flex justify-content-md-center'>
              <button
                className='btn btn--lg btn-orange--extra fs-14 fw-700 rounded-3 ml-lg-0 text-none'
                onClick={() => {
                  handleClick()
                  sendDatalayerEvent(dataLayer)
                  setDataLayer(dataLayer)
                }}
              >
                Contratar agora
              </button>
            </div>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default ComoContrataroSeguroPix
